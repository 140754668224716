












































import { PendingBprData } from '@/jbi-shared/types/cplus-bpr.types';
import { opsToText } from '@/utils/quill-delta.util';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import Op from 'quill-delta/dist/Op';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BprWarningModal extends mixins(ViewModeMixin) {
  @Prop()
  public unselectedLinkedBprs!: PendingBprData[];

  public opsToText(content: Op[]) {
    return opsToText(content);
  }

  public confirm() {
    this.$emit('confirm');
    this.$emit('close');
  }
}
