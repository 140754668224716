































































































import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop, Provide } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { PendingBprData } from '@/jbi-shared/types/cplus-bpr.types';
import { opsToText } from '@/utils/quill-delta.util';
import { QuillContainerElement } from '@/store/modules/documents/types/quill.types';
import Delta from 'quill-delta';
import { PendingCriterionData } from '@/jbi-shared/types/criterions.types';
import BprWarningModal from './BprWarningModal.vue';
import Op from 'quill-delta/dist/Op';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class StagingBprForm extends mixins(ViewModeMixin) {
  @Prop()
  public existingBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public newlyAddedBprs!: PendingBprData[];
  @Prop()
  public dirtyBprs!: PendingBprData[];
  @Prop()
  public dirtyCriterions!: PendingCriterionData[];

  public selectedBprs: PendingBprData[] = [];

  get editorOption() {
    return {
      modules: {
        toolbar: false
      },
      readOnly: true
    };
  }

  public saveBprs() {
    // Check for any unselected linked BPR(s).
    if (this.hasUnselectedLinkedBpr) {
      this.updateBprs();
      return;
    } else {
      /* If there is an audit criteria linked to a BPR that is not selected,
       * we want to prompt a warning to the user to notify them that we are
       * not selecting a linked BPR. */
      this.$buefy.modal.open({
        parent: this,
        component: BprWarningModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          unselectedLinkedBprs: this.unselectedLinkedBprs
        },
        events: {
          confirm: this.updateBprs
        }
      });
    }
  }

  public updateBprs() {
    this.$emit('update:bprs', this.selectedBprs);
    this.$emit('close');
  }

  public formatBpr(bpr: PendingBprData) {
    return opsToText(bpr.content);
  }

  public sanitizeBprContent(contents: Op[]): Op[] {
    if (
      !contents.length ||
      (contents.length > 0 && !(contents[0].insert! as string).trim())
    ) {
      contents = [{ insert: '\n\n' }];
    }

    return contents;
  }

  /*
   * For BPR values, they support quill delta values like superscript, subscript, etc.
   * Since these are not string values, in order to display these values appropriately,
   * we have to set the contents and display it via Quill Editor.
   */
  public initBprOptions() {
    this.existingBprs.forEach((bpr, index) => {
      const refId = 'existingBprValue' + index;
      /* When using refs within v-for, this.$refs[key] returns an array
       * of elements rather than a single element, even if only one exists.
       * This is why we have to access index [0] after retrieving the ref by refId. */
      const bprEditorInstance = ((this.$refs[
        refId
      ] as any)[0] as QuillContainerElement).quill;
      // Clone data to avoid affecting to props, we shouldn't change props directly
      let bprContent: Op[] = JSON.parse(JSON.stringify(bpr.content));
      // HACK: We will have the data like this if we have an empty value in Microsoft word
      // [{"insert":"\n"}] or []
      // When you setContents to Quill editor, if your data only has "\n", it will show the placeholder "Insert text here..."
      bprContent = this.sanitizeBprContent(bprContent);
      bprEditorInstance.setContents(new Delta(bprContent));

      // Disable BPR quill editor to avoid editing bpr value
      bprEditorInstance.disable();
    });

    this.newlyAddedBprs.forEach((bpr, index) => {
      const refId = 'newlyAddedBprValue' + index;
      const bprEditorInstance = ((this.$refs[
        refId
      ] as any)[0] as QuillContainerElement).quill;

      // Clone data to avoid affecting to props, we shouldn't change props directly
      let bprContent = JSON.parse(JSON.stringify(bpr.content));
      // HACK: We will have the data like this if we have an empty value in Microsoft word
      // [{"insert":"\n"}] or []
      // When you setContents to Quill editor, if your data only has "\n", it will show the placeholder "Insert text here..."
      bprContent = this.sanitizeBprContent(bprContent);
      bprEditorInstance.setContents(new Delta(bprContent));

      // Disable BPR quill editor to avoid editing bpr value
      bprEditorInstance.disable();
    });
  }

  public autoSelectIdenticalPreviousBpr() {
    const remainder = this.dirtyBprs.filter((bpr) => {
      return !this.newlyAddedBprs.includes(bpr);
    });
    this.selectedBprs = this.selectedBprs.concat(remainder);
  }

  /*
   * There is a dependency between audit criteria and BPR(s).
   * An audit criteria MUST be linked to a BPR that exists in the same
   * document.
   *
   * If there is an audit criteria linked to a BPR that is not selected,
   * this function will return false.
   * we want to prompt a warning to the user to notify them that we are
   * not selecting a linked BPR.
   */
  get hasUnselectedLinkedBpr() {
    return this.unselectedLinkedBprs.length <= 0;
  }

  /*
   * Returns a list of unselected BPR(s) that have audit criteria(s)
   * linked to them in this pending document.
   */
  get unselectedLinkedBprs() {
    /* We use a set to store unique bpr values since multiple
       AC can be linked to the same BPR. */
    const totalLinkedBprs = new Set<string>();

    this.dirtyCriterions.forEach((criterion) => {
      criterion.bprs?.forEach((linkedBpr) => {
        /* Convert to string in order to identify duplicate values easily.*/
        totalLinkedBprs.add(
          String(linkedBpr.tempId || linkedBpr.bprSubSectionId)
        );
      });
    });

    const unselectedLinkedBprs = [];
    const selectedBprIds = this.selectedBprs.map((selectedBpr) =>
      String(selectedBpr.tempId || selectedBpr.bprSubSectionId)
    );

    for (const linkedBprId of totalLinkedBprs) {
      if (!selectedBprIds.includes(linkedBprId)) {
        unselectedLinkedBprs.push(linkedBprId);
      }
    }

    // Convert bpr id(s) to actual bpr data with content.
    return unselectedLinkedBprs.map((linkedBprId) => {
      /* We try to map linked bpr id to pending bpr list (dirtyBpr) */
      return (
        this.dirtyBprs.find(
          ({ tempId, bprSubSectionId }) =>
            linkedBprId === String(bprSubSectionId) ||
            linkedBprId === String(tempId)
        ) ||
        /* If we can't find it in the pending bpr list,
           then we'll try to map to the previous bpr list (existingBpr) */
        this.existingBprs.find(
          ({ tempId, bprSubSectionId }) =>
            linkedBprId === String(bprSubSectionId) ||
            linkedBprId === String(tempId)
        )
      );
    });
  }

  /*
   * Returns true if the existingBpr does not exist in the dirtyBprs list.
   * - This is used to indicate whether a previous bpr has been removed in
   *   the pending document.
   */
  public isRemovedBpr(existingBpr: PendingBprData) {
    return !this.dirtyBprs.find((bpr) => {
      return (
        JSON.stringify(bpr.content) === JSON.stringify(existingBpr.content)
      );
    });
  }

  public mounted() {
    this.initBprOptions();
    this.autoSelectIdenticalPreviousBpr();
  }
}
